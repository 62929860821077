.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blackLink {
  color: black !important;
}

.whiteLink {
  color: white !important;
}

.subtitle {
    font-size: 14px;
    color: gray;
}

.About {
  text-align: center;
}